<script lang="ts">
	import { onMount } from 'svelte';
	import type {Industry} from '$lib/domain/industry.interface';

	export let industry: Industry;
	let subIndustries = {
			Healthcare: [
					{
							title: 'Telemedicine',
							url: '/telemedicine-software-development/'
					},
					{
							title: 'LIMS',
							url: '/laboratory-information-management-systems/'
					},
					{
							title: 'EHR and EMR',
							url: '/electronic-medical-records-software-development/'
					},
					{
							title: 'CRM for healthcare',
							url: '/healthcare-crm-software/'
					},
					{
							title: 'Wellness',
							url: '/fitness-wellness-development/'
					}
			],
			Logistics: [
					{
							title: 'Fleet management',
							url: '/fleet-management-software-development-company/'
					},
					{
							title: 'Freight forwarding',
							url: '/freight-forwarding-software-solutions/'
					},
					{
							title: 'Warehouse management',
							url: '/warehouse-management-system-wms/'
					},
					{
							title: 'CRM for Logistics',
							url: '/crm-software-for-logistics/'
					},
					{
							title: 'Mobility',
							url: '/mobility-software-development/'
					}
			]
	}

	const uniqueClassName = industry ? industry.icon.replace('.webp', '') : null;

	onMount(() => {
			if (typeof IntersectionObserver === 'undefined') {
					return;
			}

			let observer = new IntersectionObserver(
					(entries, observer) => {
							entries.forEach((entry) => {
									if (!entry.isIntersecting) {
											return;
									}

									const htmlTarget = entry.target as HTMLElement;
									htmlTarget.style.backgroundImage = `url(/industries/${industry.icon})`;
									observer.unobserve(htmlTarget);
							});
					},
					{ threshold: 0, rootMargin: '20px' }
			);

			document.querySelectorAll(`.${uniqueClassName}`).forEach((entry) => observer.observe(entry));
	});
</script>

<article>
	<div class="industry {uniqueClassName}">
		<div>
			<h3>{industry.title}</h3>
			
			<a href="{industry.url}">
				<p>
					{@html industry.content}
				</p>
				{#if subIndustries[industry.title]}
					<ul>
						{#each subIndustries[industry.title] as link}
							<li>
								<a href="{link.url}">{link.title}</a>
							</li>
						{/each}
					</ul>
				{/if}
			</a>
		</div>
	</div>
</article>

<style lang="scss">
	@import '$styles/vars';

	article {
		width: 100%;
		height: 525px;
		margin-top: 48px;

		.industry {
			width: 80%;
			position: relative;
			left: 20%;
			background-size: cover;
			background-repeat: no-repeat;
			border-radius: 24px;
			min-height: 525px;

			h3 {
				text-align: left;
				font-size: 18px;
				font-weight: 700;
				line-height: 22px;
			}

			div {
				position: relative;
				top: 96px;
				right: 25%;
				width: 588px;
				height: fit-content;
				padding: 36px 24px;
				background-color: $light-grey;
				color: $main-black;
				white-space: pre-line;
				border: 4px solid transparent;
				border-radius: 12px;
				box-sizing: border-box;
				overflow: hidden;

				&:hover {
					border: 4px solid $green;
				}
				p {
					margin-bottom: 0;
				}
				a {
					color: $main-black;
				}
				ul {
		  		margin: 23px 0 0;
		  		padding: 0;
					list-style: none;
					display: flex;
					flex-wrap: wrap;
					width: 80%;
					li {
						margin: 0;
						padding: 3px;
						a {
							display: block;
			  			padding: 2px 6px;
							background-color: $green;
							color: $main-white;
							font-size: 14px;
							font-weight: 600;
							line-height: 28px;
							border-radius: 6px;
							&:hover {
								background-color: $dark-green;
							}
						}
					}
				}
			}
		}
	}

	@media (min-width: $portrait-phone-size) and (max-width: $tablet-size) {

		article {
			margin-bottom: 60px;

			.industry {
				width: 90%;
				left: 10%;

				div {
          padding: 24px 12px 12px;
					right: 11%;
					width: 350px;
					height: fit-content;
				}
			}
		}
	}

	@media (max-width: $portrait-phone-size) {

		article {
			margin-top: 24px;
			height: 545px;

			.industry {
				width: 110%;
				left: -5%;
				min-height: unset !important;
				border-radius: unset;
				background-position: center;

				div {
					padding: 24px 12px;
					top: 245px;
					left: 5%;
					width: 90%;
					height: 300px;

					h3{
						text-align: center;
					}
				}

				p {
					margin-bottom: 0;
		  		line-height: 20px;
				}
			}
		}
	}
</style>
